import { environment as defaultEnv } from './default-environment';

export const environment = {
  ...defaultEnv,
  production: false,
  backendUri: 'https://sales-se-api.hl-display.com/',
  allowedPriceLists: [],
  hideMobile: false,
  discountCustomerFinancialGroup: {},
  bolag: '1210',
  sales: true,
  analyticsId: '',
  sentryDsn: '',
  quoteConfirmationEmailAddress: 'info.no@hl-display.com',
  orderOurReference: 'GUNG',
  externalOrderType: 8,
  mainCountry: 'no',
  mainCustomer: '',
  stripeApiKey: undefined,
  availability: {
    useConfigTransform: true,
    bufferDays: 1,
    showTransportTime: false
  },
  getArticlesOutsidePriceLists: true,
  showFavourites: true,
  showApprovalQuote: true,
  showCreateSampleOrder: true
};
